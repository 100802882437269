<template>
  <div id="page-user-list">
    
      <form method="POST" enctype="multipart/form-data"  @submit.prevent="submitForm">
       <div class="vx-row mb-6">

        <div class="vx-col w-full md:w-2/3">
          <div class="vx-card p-6 mb-6">
            <BannerForm></BannerForm>
          
          </div>
        </div>
        <div class="vx-col w-full md:w-1/3">
            <div class="vx-card p-6 mb-6">
              <BannerImage></BannerImage>
            </div>
        </div>
       </div>

            <!-- Save & Reset Button -->
        <div class="vx-card p-4 mb-4">
            <SettingInfo></SettingInfo>
 
            <div class="flex flex-wrap items-center justify-end">
              <vs-button button="submit" class="ml-auto" >Save Changes</vs-button>
            </div>
        </div>
      </form> 
	</div>
</template>

<script>
import vSelect from 'vue-select'
import BannerForm from './common/BannerForm';
import SettingInfo from './common/SettingInfo';
import BannerImage from './common/BannerImage';
import {BANNER} from '../../../constant/entity-identifier';
import loaderMixin from '../../../mixins/loaderMixin';
import { mapGetters, mapActions } from 'vuex'

import {updateImage} from '../../../services/image';

export default {
  components: {
    vSelect,
    BannerForm,
    SettingInfo,
    BannerImage
  },
  mixins: [loaderMixin],
  computed: {
    ...mapGetters({
        form: 'banner/getFormObj',
        images: 'banner/getImages',
        image_alt: 'banner/getImageAlt',
    }),
    getBannerId() {
        return this.$route.params.bannerId;
    },
  },
  created() {
    this.clearForm();
    this.clearValidationErrors();
     this.openLoading();
     this.fetchAndSetBannerById(this.getBannerId).then(() => {
       this.closeLoading();
     }).catch((r) => this.closeLoading());
  },
  methods: {
    ...mapActions({
      updateBannerAction: 'banner/updateBannerAction',
      fetchAndSetBannerById: 'banner/fetchAndSetBannerById',
      clearForm: 'banner/clearForm',
      clearValidationErrors: 'validation/clearValidationErrors',
    }),
    prepareForm(data, type, method) {
      let formData = new FormData();
      formData.append("_method", method);
      formData.append("entity_id", data.id);
      formData.append("entity", type);
      (method == 'PATCH') ? formData.append("file_ids[]", data.images[0].id) : '';
      formData.append("images[]", this.images[0]);
      formData.append("alt[]", this.image_alt[0]);
      return formData;
    },
    submitForm() {
     this.openLoading();
      this.updateBannerAction({
        bannerId: this.getBannerId,
        data: this.form
      }).then( async response => { 
        if(response.status === 202) {
            if(this.images.length > 0) {
                if(response.data.data.images){
                    await updateImage(response.data.data.images[0].id, this.prepareForm(response.data.data, BANNER, 'POST'));
                }else{
                    await storeImage(this.prepareForm(response.data.data, BANNER, 'POST'));
                }
                this.closeLoading();
            }else{ this.closeLoading();
            }  
        this.$vs.notify({ 
            text: 'Banner updated successfully.',
            title: 'Banner Updated',
            color: 'success',
            position: 'top-right'
        });
        this.$router.push('/banners');}
      }).catch(() => this.closeLoading());
    },
  },
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
