<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Banner Details</span>
      </div>
      <div class="vx-col w-full">
        <vs-input 
          class="w-full mt-4" 
          label="Title" 
          :danger="validation.title?true:false"
          :danger-text="validation.title?validation.title[0]:''"
          :value="form.title"
          @input="updateForm({key: 'title', value: $event})" />

        <div class="mt-4 ">
          <label class=" vs-input--label">Region</label>
          <v-select 
            :value="formDropDown.package_region_id"
            @input="updateForm({key: 'package_region_id', value: $event})"
            :options="package_regions" />
            <span class="text-danger text-sm" 
              v-show="validation.package_region_id?true:false">
              {{ validation.package_region_id?validation.package_region_id[0]:''}}
            </span>
        </div>
      </div>
  </div>
  </div>
</template>

<script>

import vSelect,{vOption} from 'vue-select'
import { mapGetters,mapActions } from 'vuex'

export default {  
  components: {
    vSelect,
    vOption
  },
  mounted(){
    this.fetchPackageRegions();
  },
  computed: {
    ...mapGetters({
        form: 'banner/getFormObj',
        validation: 'validation/getValidationErrors',
        formDropDown: 'banner/getFormDropdownObj',
        package_regions: 'package_region/getPackageRegionsDropdown'
    })
  },
  methods: {
    ...mapActions({
      updateForm: 'banner/updateForm',
      fetchPackageRegions: 'package_region/fetchAndSetPackageRegionsDropdown'
    })
  }
}
</script>
